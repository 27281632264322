<template>
  <div class="answers-list">
    <classroomsAnswersListBreadcrumbComponent />
    <classroomsAnswersListAnswerTableComponent :page_data="submissions" />
  </div>
</template>

<script>
import classroomsAnswersListBreadcrumbComponent from "@/components/classrooms/answersList/breadcrumd/classroomsAnswersListBreadcrumbComponent.vue";
import classroomsAnswersListAnswerTableComponent from "@/components/classrooms/answersList/answerTable/classroomsAnswersListAnswerTableComponent.vue";
export default {
  name: "answersList",
  components: {
    classroomsAnswersListBreadcrumbComponent,
    classroomsAnswersListAnswerTableComponent,
  },
  data() {
    return {
      submissions: null,
      dateNtim: null,
    };
  },
  created() {
    this.getSubmissions();
  },
  methods: {
    getSubmissions() {
      this.$http
        .get(
          `admin/assignments/${this.$route.params.id}/students?course_id=${this.$route.query.course_id}`
        )
        .then((res) => {
          this.submissions = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
