<template>
  <div class="answer-table">
    <div class="answer-box-main">
      <b-card>
        <b-card-header class="header d-flex justify-content-between">
          <div class="text-left">{{ $t("g.student") }}</div>
          <div>{{ $t("g.date") }}</div>
          <div>{{ $t("g.final_score") }}</div>
          <div>{{ $t("g.no_submissions") }}</div>
        </b-card-header>
      </b-card>
      <b-card
        v-for="(item, index) in page_data"
        :key="index"
        class="answer-item"
      >
        <b-card-header
          @click="toggleCollapse(index)"
          class="d-flex justify-content-between card-header"
        >
          <div class="item-data">
            <b-img v-if="item.user.media.length > 0" class="img" :src="item.user.media[0].path" />{{
              item.user.first_name + " " + item.user.last_name
            }}
          </div>
          <div class="item-data text-center">
            {{ formate(item.created_at) }}
          </div>
          <div class="scores item-data text-center">
            <span>{{ item.assignment_submissions.final_score }}</span>
          </div>
          <div class="item-data custom-item text-center">
            {{ item.assignment_submissions.submissions.length }}
            <b-img
              :src="require(`@/assets/images/icons/arrow.png`)"
              :class="activeCollapse === index ? 'active' : null"
            />
          </div>
        </b-card-header>
        <b-collapse :visible="activeCollapse === index" c0-lass="my-2">
          <div
            class="answer-box"
            v-for="(answer, index_answer) in item.assignment_submissions
              .submissions"
            :key="answer.id"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <b-link
                  :to="`/class-rooms/${
                    item.enrollments ? item.enrollments[0].id : null
                  }/assignment/${$route.params.id}/${index_answer + 1}/${
                    answer.id
                  }`"
                >
                  {{ `${$t("g.submission")} ${index_answer + 1}` }}
                </b-link>
              </div>
              <div class="text-center">{{ formate(answer.submit_date) }}</div>
              <div class="scores item-data text-center">
                <span>{{
                  answer.submission_grade ? answer.submission_grade.score : null
                }}</span>
              </div>
            </div>
            <hr
              v-if="
                index_answer !=
                item.assignment_submissions.submissions.length - 1
              "
            />
          </div>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BCard,
  BCollapse,
  BImg,
  BCardHeader,
  BLink,
} from "bootstrap-vue";
import formatDate from "@/composables/format-date/format-date";
export default {
  components: {
    BTable,
    BButton,
    BCard,
    BCollapse,
    BImg,
    BCardHeader,
    BLink,
  },
  props: ["page_data"],
  data() {
    return {
      formate: formatDate,
      activeCollapse: null,
    };
  },
  methods: {
    toggleCollapse(index) {
      if (this.activeCollapse === index) {
        this.activeCollapse = null;
      } else {
        this.activeCollapse = index;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_classroomsAnswersListAnswerTableComponent.scss";
</style>
