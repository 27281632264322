<template>
  <div class="answer-breadcrumb">
    <app-breadcrumb class="px-1" />
  </div>
</template>

<script>
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
export default {
  components: {
    AppBreadcrumb,
  },
}
</script>

<style lang="scss">
@import './_classroomsAnswersListBreadcrumbComponent.scss';
</style>